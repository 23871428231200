﻿@import '../../../../../Common/src/scss/components/react/SearchBar.scss';

.SearchBar {
    border-color: $dark;
    top: 135%;

    &.SearchBar__header {
        z-index: 200;
    }

    input.SearchBar__input {
        color: $black;
        background-color: $light !important;
    }

    &__bar {
        border-bottom: double var(--primary);
    }

    &__icon {
        color: $black;
        background-color: $light !important;
    }

    &.theme-dark {
        border: 1px solid color-mix(in srgb, var(--primary), transparent 80%);

        input.SearchBar__input,
        .SearchBar__icon {
            background-color: $gray-dark !important;
            color: $light;
        }
    }
}
