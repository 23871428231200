﻿$filter-font-family: var(--tertiary-font);

@import '../../../../../Common/src/scss/components/react/FilterDropdown.scss';

.FilterDropdown {

    &.expanded {
        background-color: $gray-dark;

        > button {
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25);

            > i {
                color: var(--light);
            }

            > span {
                color: var(--primary);
            }
        }
    }

    .FilterDropdown__button-list {
        background-color: $gray-dark;
        button {
            width: 100%;

            &.selected {
                background-color: var(--primary);
                padding: .5em;
            }

            > span {
                text-decoration: none !important;
            }
        }

        label {
            > span {
                color: $gray-secondary;
            }
        }
    }
}
