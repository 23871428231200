﻿@import '../../../../../Common/src/scss/components/react/ProductView.scss';

.ProductView {
    .ProductTitle {
        p {
            font-size: 2.25rem;
            text-transform: uppercase;
            font-weight: $font-weight-bolder;
            font-family: var(--primary-font);
            margin-bottom: 0.5rem;
            line-height: 1.2;
        }
    }
    
    @include media-breakpoint-down(md) {
        .ProductTitle {
            p {
                font-size: calc(1.35rem + 1.2vw);
            }
        }
    }
}

