.EPiServerForms {
    padding: 1em;
    background-color: $gray-light;

    .Form__Title {
        color: $semi-dark;
    }

    .Form__Description {
        font-family: var(--tertiary-font);
        color: $semi-dark;
    }

    .Form__Element {
        .Form__Element__Caption {
            display: block !important;
        }

        textarea,
        select {
            width: 100%;
        }
    }

    .FormSubmitButton {
        background-color: var(--primary);
        color: $white;
        border: none;
        padding-top: 0.5em;
        padding-left: 2em;
        padding-right: 2em;
        padding-bottom: 0.25em;
    }
}
