.articlePage {
    .title {
        @include font-size(32px, true);
    }

    .subtitle {
        @include font-size(18px, true);
        text-transform: uppercase;
        font-family: var(--tertiary-font);
        font-weight: bold;
    }
}

.section-carp--specimen {
    .articlePage {
        .title,
        .main-p2 {
            color: var(--dark);
        }
    }
}

.article__cardgrid {
    .article__card {
        .card-img-top {
            filter: brightness(50%);
        }

        .card-title {
            font-family: var(--tertiary-font);
            text-transform: uppercase !important;
            @include font-size(18px);
            margin-bottom: 1rem;
        }

        .publishdate {
            display: none;
        }

        .card__footer-link {
            color: var(--light);
            font-family: var(--tertiary-font);
            @include font-size(12px);
            font-weight: bold;
            letter-spacing: normal;

            .fa-arrow-right {
                color: var(--primary);
            }
        }

        .social__link {
            color: var(--light);
        }
    }
}

.article__cardrow {
    .card-img-top {
        filter: brightness(50%);
    }

    .publishdate {
        display: none;
    }

    .card.article__cardrow__card-style1 {
        .card__footer-link {
            bottom: 0.5em;
        }
    }
}
