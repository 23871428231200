﻿@import '../../../../Common/src/scss/sections/_header.scss';

.search-button,
.user-menu-button {
    border: none !important;
    background-color: var(--primary) !important;
}

.header__banner {
    text-align: center !important;
    color: $white;
    @include font-size(14);
    background: var(--primary);
    text-align: center;

    @media (hover: hover) {
        &:hover {
            color: $white;
        }
    }
}

.header__section {
    background-color: var(--primary);
}

.header__section__sonubaits {
    width: 100% !important;
    max-width: unset;
}

.header__my-account-container {
    color: $white;
    height: 100%;
    padding-top: 12px;
    padding-bottom: 12px;

    button.header__minicart {
        background: transparent;
        border: none;

        small {
            vertical-align: middle;
        }
    }

    .search-button,
    .user-menu-button,
    .header__language-selector-container,
    .header__login-link {
        height: 32px;
    }

    .search-button,
    .user-menu-button,
    .header__minicart,
    .header__login-link {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.header__links-container {
    display: flex;

    .label-search {
        display: none;
        @media (min-width: 1275px) {
            display: inline;
        }
    }
}

.header__links-child {
    flex: 1;
}

.header__usermenu {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

.header__logo-container {
    padding-top: 12px;
    padding-bottom: 12px;
}

.header__logo {
    position: relative;
    z-index: 1;
}
