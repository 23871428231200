﻿.footer__section {
    padding-top: 122px;
    background-color: $gray;
    color: $white !important;

    .copyright {
        color: $gray-medium;
        font-weight: 400;
        @include font-size(12px);
    }

    @include media-breakpoint-down('md') {
        padding-left: 15% !important;
        padding-right: 15% !important;
        padding-top: 60px;
    }

    @include media-breakpoint-down('xs') {
        padding-left: 5% !important;
        padding-right: 5% !important;
        padding-top: 60px;
    }
}

.footer__navgroup {
    .footer__navgroup-header {
        color: var(--primary);
        text-transform: uppercase;
    }

    .footer__navgroup-header__mobile {
        @include font-size(14px);

        .btn {
            color: $white;
        }
    }

    .navgroup__links {
        list-style: none;
        padding-left: 0;
        @include font-size(14px);

        @include media-breakpoint-down('md') {
            display: none;

            &.show {
                display: block;
            }
        }

        .navgroup__link a {
            font-weight: normal !important;
            color: $white !important;
        }
    }

    .btn {
        padding: 0px;
    }

    .accordion {
        margin-bottom: 1.5em;
    }

    .fa-chevron-down,
    .fa-chevron-up {
        font-size: 12px;
    }
}

.footer__tactical-nav {
    ul {
        list-style-type: none;
        padding-left: 0;

        li {
            display: inline;

            a {
                color: var(--primary);
                @include font-size(14px);
            }
        }
    }
}
