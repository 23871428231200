/*
 ==== Color Palette ==========================
*/
// Override Bootstrap defaults
$orange: #ff6500;
$blue: #4682b4;
$orange-layer-1: #e9540d;
$white: #fff;
$black: #000;
$gray-primary: #999;

$primary: $orange;
$primary-layer-1: $orange-layer-1;
$secondary: $black;
$tertiary: $gray-primary;
$light: $white;
$dark: $black;

// Lew's custom colors
// Question,  should we override bootstrap grays?
//   dark -> gray-900
//   primary -> gray-800
//   secondary -> gray-600
//   ghost -> gray-100
$gray: #1d2125;
$gray-dark: #333;
$gray-secondary: #f1f1f1;
$gray-ghost: #f1f1f1;
$semi-dark: #666;
$gray-mediumlight: #ccc;
$gray-light: #f1f1f1;
$gray-medium: #999;
$checkbox-fill-color: $primary;

$info: $primary;
$success: $light;

$text-highlight: rgba(215, 239, 255, 0.75);
/*
 ==== Fonts ==============================
*/
$primary-font: 'Josefin-Sans', arial, sans-serif;
$secondary-font: 'Oswald', sans-serif;
$tertiary-font: 'Lato', arial, sans-serif;
$font-family-base: $primary-font !default;
$font-weight-bolder: 900 !default;
$font-size-base: 1.2rem; // Assumes 16px base * 1.2 = 19ish
$font-size-sm: 0.875rem;
$input-btn-font-size: 1.188rem;
$input-btn-font-size-sm: 1rem;
$input-btn-font-size-lg: 1.438rem;
$line-height-base: 1.4;
$enable-responsive-font-sizes: true !default;

// Default css variable fonts
:root {
    --primary-font: #{$primary-font};
    --secondary-font: #{$secondary-font};
    --tertiary-font: #{$tertiary-font};
    --font-family-base: #{$primary-font};
    --primary-contrast: #{$light};
    --secondary-contrast: #{$light};
    --checkbox-fill-color: var(--primary);
    --link-color: #{$black};
    --grid-odd-background: #{$gray-light};
    --btn-font-size: #{$input-btn-font-size};
    --btn-font-size-lg: #{$input-btn-font-size-lg};
    --btn-font-size-sm: #{$input-btn-font-size-sm};
}

$h1-font-size: 62px;
$h2-font-size: 53px;
$h3-font-size: 45px;
$h4-font-size: 38px;
$h5-font-size: 30px;
$h6-font-size: 23px;

$h1-mobile-font-size: 53px;
$h2-mobile-font-size: 45px;
$h3-mobile-font-size: 38px;
$h4-mobile-font-size: 30px;
$h5-mobile-font-size: 23px;
$h6-mobile-font-size: 19px;

$headings-font-weight: 700 !default;

$display1-size: 120px;
$display2-size: 90px;
$display3-size: 38px;
$display4-size: 19px;

$display1-weight: 900;
$display2-weight: 700;
$display3-weight: 700;
$display4-weight: 700;

/*
 ==== Links  ==============================
*/
$link-color: $black;
//$link-decoration: underline !default;
/*
 ==== Navigation  ==============================
*/
$navbar-dark-active-color: $white;
$navbar-dark-hover-color: $orange;
$navbar-dark-color: $orange;
/*
 ==== Buttons  ==============================
*/
$input-btn-font-family: $secondary-font !default;
$btn-font-weight: 900;
$btn-border-radius: 0px;
$btn-border-radius-lg: 0px;
$btn-border-radius-sm: 0px;
$btn-line-height: 18px;
$btn-line-height-lg: 22px;

/*
 ==== Input  ==============================
*/
$input-font-size: 24px !default;
$input-border-color: $black;
$input-border-width: 2px;
$input-border-radius: 0px;
$input-border-radius-lg: 0px;
$input-border-radius-sm: 0px;
/*
 ==== Alerts  ==============================
*/
$alert-border-radius: 0px;
$alert-border-width: 2px !default;
$alert-border-level: 0 !default;
/*
 ==== Forms  ==============================
*/
$enable-validation-icons: false;
$form-feedback-valid-color: $black;

$input-bg: $gray-light;
