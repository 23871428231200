﻿@import '../../../../Common/src/scss/components/_navigation_main.scss';

.navigation__main {
    height: 100%;
    z-index: 2;

    .navbar-nav {
        height: 100%;

        &.navbar__toplevel {
            .nav-item {
                .nav-link {
                    @include font-size(16px);
                    text-transform: none;

                    padding-top: 12px;
                    padding-bottom: 6px;
                    padding-left: 0 !important;
                    padding-right: 0 !important;
                }
            }
        }

        &.navbar__subsection {
            .nav-item {
                .nav-link {
                    @include font-size(14px);
                    padding-top: 6px;
                    padding-bottom: 6px;

                    .nav-link__content {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }
            }
        }

        .nav-item {
            height: 100%;

            .nav-link {
                color: $white;

                .nav-link__content {
                    padding: 0.5em;
                }
            }

            .nav-item__children-panel {
                display: none;
                position: absolute;
                background-color: $gray-dark;
                z-index: $zindex-popover;

                .nav-item__children-panel {
                    left: 100%;
                    top: 0;
                }

                .nav-item__children-list {
                    list-style-type: none;
                    padding-left: 0;

                    li {
                        a {
                            color: $white;
                            @include font-size(16px);
                            font-weight: 600;
                            text-transform: uppercase;
                            overflow-x: hidden;
                            text-overflow: ellipsis;
                        }

                        @media (hover: hover) {
                            &:hover {
                                > .nav-item__children-panel {
                                    display: block;
                                }
                                > a {
                                    text-decoration: none;
                                    background-color: var(--primary);
                                }
                            }
                        }
                    }
                }
            }

            @media (hover: hover) {
                &.active,
                &:hover {
                    .nav-link {
                        .nav-link__content,
                        .nav-link__content i {
                            text-decoration: underline;
                            text-decoration-thickness: 2px;
                        }
                    }
                }

                &:hover > .nav-item__children-panel {
                    display: block;
                }
            }
        }
    }
}

.navbar-toggler {
    color: $white;
    z-index: 1;
}

.navigation__mobile {
    background-color: $black;
    position: fixed;
    width: 100%;
    height: 100%;
    left: -100%;
    top: 0;
    z-index: $zindex-modal;
    transition: left 0.5s;
    overflow-y: auto;

    .navigation__mobile-nav {
        border-bottom: 2px solid $white;
    }

    &.show {
        left: 0;
    }

    .header__social {
        text-align: center;
    }

    .navigation__mobile-collapse-button {
        border: none;
        background-color: transparent;
        color: $white;
        @include font-size(36px);
    }

    .navigation__mobile-list {
        list-style-type: none;
        padding-left: 0;

        li {
            a {
                @include font-size(18px);
                text-transform: uppercase;
                color: $white;
                font-weight: 500;

                &.active {
                    text-decoration: underline;
                    text-decoration-color: var(--primary);
                    text-decoration-thickness: 2px;
                }

                &:hover {
                    text-decoration: none;
                }

                &.has-children:after {
                    float: right;
                    content: '›';
                    margin-right: 0;
                }
            }
        }
    }
}

.navigation__mobile__child {
    z-index: $zindex-modal + 1;

    .navigation__mobile-child-header {
        @include font-size(28px);
        border-bottom: 2px solid var(--primary);
        text-transform: uppercase;
    }

    .navigation__mobile-return-home {
        button {
            text-transform: none;
        }
    }

    .menucta__links__mobile img {
        filter: invert(1);
    }
}
