﻿@import '../../../../../Common/src/scss/components/react/SearchResults.scss';

.SearchResults {
    .SearchFacets {
        .FacetFilter {
            .dropdown-drawer {
                .btn {
                    &.selected {
                        background-color: var(--primary);
                        color: $white;
                    }
                }
            }
        }
    }

    .SearchResultTabs__content__result {
        a:hover {
            text-decoration: none;
        }

        .excerpt {
            color: $gray-dark;
        }
    }

    .SearchResultTabs {
        .mobile_buttons {
            &.container,
            .row {
                height: auto;

                button {
                    padding-top: 0.25em;
                    padding-bottom: 0.25em;
                }
            }
        }
    }
}
