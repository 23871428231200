.carousel {
    .carousel__header--style2 {
        @include font-size(18px);

        > h3 {
            font-family: var(--tertiary-font);
            @include font-size(32px);

            &::after {
                display: none;
            }
        }
    }

    .carousel__card--style3 {
        min-height: 600px !important;

        .red-devider {
            display: none;
        }
    }

    .carousel__card--style4 {
        min-height: 280px !important;

        .carousel__card-background {
            filter: brightness(50%);
        }
    }

    .carousel__card--style6 {
        &:hover {
            background-color: transparent !important;
        }

        background-color: transparent;

        .btn {
            letter-spacing: normal;
            font-family: var(--tertiary-font);
            font-weight: bold;
            @include font-size(18px);
        }
    }

    .slick-list {
        padding-right: 0;
    }
}
