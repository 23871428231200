﻿@import '../../../../../Common/src/scss/components/react/CatalogCard.scss';

@mixin border-between($column-count) {
    &:not(:nth-child(#{$column-count}n + #{$column-count})) {
        border-right: 1px solid $gray-light;
    }

    border-bottom: 1px solid $gray-light;

    &:nth-child(#{$column-count}n + 1):nth-last-child(-n + #{$column-count}),
    &:nth-child(#{$column-count}n + 1):nth-last-child(-n + #{$column-count}) ~ .CatalogCard {
        border-bottom: none !important;
    }
}

.CatalogCard {
    @include media-breakpoint-down('md') {
        @include border-between(2);
    }

    @include media-breakpoint-up('lg') {
        @include border-between(3);
    }

    .subheader,
    .CatalogCard__prices > div {
        @include font-size(14px, true);
        font-weight: 600;
        font-family: var(--secondary-font);
    }

    &__prices {
        margin-top: 0.25em !important;
    }

    .ProductBadge {
        text-align: center;
        margin-bottom: 0.5em;
    }
}
