body {
    &.section-carp--specimen {
        color: var(--dark);

        .search {
            .fas.fa-search {
                color: var(--dark);
            }
        }

        .DetailsLinks {
            .LinkItem {
                color: var(--dark);
            }
        }
    }
}
