.ProductDetails {
    color: var(--dark);
    
    .ProductChildTable {
        font-family: var(--tertiary-font);

        thead {
            @include font-size(18px);
            text-transform: uppercase;
            font-weight: 700;
        }

        tbody {
            @include font-size(16px);
        }
    }
}
