.menucta__links {
    .menulink__item {
        a {
            color: $white !important;

            &:hover,
            &:active {
                color: var(--primary) !important;
            }
        }
    }
}
