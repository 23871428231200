﻿@import '../../../Common/src/scss/components/_inputs.scss';

input:not([type='checkbox']):not([type='radio']):not([type='submit']),
textarea {
    background-color: $gray-mediumlight;
    border: 1px solid $gray-dark;
    border-radius: 3px;
}

select {
    border: 1px solid $gray-dark !important;
    border-radius: 3px;
    background-color: $gray-light !important;
}
