﻿@import '../../../../../Common/src/scss/components/react/MobileFiltersMenu.scss';

.MobileFiltersMenu {
    .MobileFiltersMenu__list {
        padding-left: 1em;
        padding-right: 1em;

        > ul {
            > li {
                > .Selector {
                    padding: 0.5em 1em;

                    margin-top: 0.2em;
                    margin-bottom: 0.2em;

                    &.selected {
                        background-color: var(--primary);
                        color: $white;
                    }
                }
            }
        }
    }
}
