.prostaffPage {
    .prostaffPage__title {
        @include font-size(36px);
        letter-spacing: normal;
    }

    .prostaffPage__protitle {
        font-family: var(--primary-font);
        letter-spacing: normal;
    }
}

.section-carp--specimen {
    .prostaffPage {
        .prostaffPage__title {
            color: var(--light);
        }
    }

    .prostaff__cardgrid {
        .title {
            color: var(--light);
        }
    }

    .prostaffPage__protitle {
        color: var(--light)t;
    }
}

.prostaff__cardgrid {
    .card {
        .card-img-top {
            filter: brightness(70%);
        }

        .title {
            word-spacing: normal;

            @include font-size(20px);
        }
    }
}

.prostaff__cardrow {
    a {
        margin-left: 0;
    }

    .card {
        h3 {
            word-spacing: normal;
            letter-spacing: normal;
            @include font-size(20px);
        }
    }
}

.prostaffQuoteBlock {
    .big-banner {
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .quote-box {
        &:not(.quote-box--no-quote) {
            &::before {
                top: -60px;
            }
        }
    }
}
