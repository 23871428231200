@import '../../../Common/src/scss/blocks/_newslettersubscriptionblock.scss';

.newsletter__subscription {
    background-color: $gray;
    margin-left: -1rem;
    margin-right: -1rem;

    button[type='submit'] {
        padding: 10px 17px;
        max-width: 212px !important;

        @include media-breakpoint-down(md) {
            padding: 2px 17px !important;
        }
    }
}
