﻿@import '../../../../../Common/src/scss/components/react/ProductBadge.scss';

.ProductBadge {
    .badge {
        text-transform: uppercase;
        padding-top: 0.5em;
        padding-bottom: 0.3em;
        padding-left: 1em;
        padding-right: 1em;
        white-space: break-spaces;

        @include media-breakpoint-down(lg) {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        @include media-breakpoint-down(md) {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }

        @include media-breakpoint-down(sm) {
            padding-left: 0.5em;
            padding-right: 0.5em;
        }
    }
}
