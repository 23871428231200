﻿@import '../../../../../Common/src/scss/components/react/ProductDescriptionTab.scss';

.ProductDescriptionTab {
    .ProductDescriptionTab__Description {
        @include font-size(16);
        color: $dark;

        p {
            @include font-size(16);
        }
    }
}
