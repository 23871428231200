@import '../../../../../Common/src/scss/components/react/LanguageSelector.scss';

.LanguageSelector {
    button.dropdown-toggle {
        i,
        span {
            color: $white;
        }
    }

    &.account-settings {
        button.dropdown-toggle {
            i,
            span {
                color: $dark;
            }
        }
    }

    &:not(.account-settings) {
        height: 100%;

        button {
            height: 100%;
        }
    }
}
