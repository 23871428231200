﻿.btn,
.btn-lg,
.btn-sm {
    text-transform: uppercase;
    font-family: var(--secondary-font);
    font-weight: normal;
}

.btn:hover,
.btn-lg:hover,
.btn-sm:hover {
    opacity: 0.9;
}

.btn:active,
.btn-lg:active,
.btn-sm:active {
    opacity: 0.8;
}

.btn-primary:focus {
    border: 2px solid var(--primary);
}

.btn-secondary:focus {
    border: 2px solid var(--secondary);
}

.btn {
    letter-spacing: 2px;
    font-size: var(--btn-font-size);
    padding: 16px 40px;
}

.btn-lg {
    letter-spacing: 1.92px;
    line-height: $btn-line-height-lg;
    font-size: var(--btn-font-size-lg);
    padding: 20px 50px;
}

.btn-sm {
    letter-spacing: 1.5px;
    line-height: 16px;
    font-size: var(--btn-font-size-sm);
    padding: 7px 28px;
}

.btn,
.btn-lg,
.btn-sm,
.btn:focus,
.btn-lg:focus,
.btn-sm:focus,
.btn:hover,
.btn-lg:hover,
.btn-sm:hover,
.btn:active,
.btn-lg:active,
.btn-sm:active {
    &.disabled {
        @extend .disabled;
    }
}

button:disabled {
    pointer-events: none;
}

.section-carp--specimen {
    .btn {
        color: var(--dark);

        &.btn-secondary {
            color: var(--light);
        }
    }

    .search {
        .btn {
            color: var(--light)
        }
    }
}
