.tabbed__content {
    .nav-tabs {
        .nav-item {
            .nav-link.active {
                background-color: transparent;
                color: var(--dark);
            }
        }
    }
}

.section-carp--specimen {
    .tabbed__content {
        .nav-tabs {
            .nav-item {
                color: var(--light);
                .nav-link {
                    color: var(--dark);
                }
                .nav-link.active {
                    background-color: transparent;
                    color: var(--light);
                }
            }
        }
    }
}
