﻿@import '../../../Common/src/scss/pages/_catalog_product.scss';

.catalog__product {
    .breadcrumb {
        padding-left: 1em;
        padding-right: 1em;
        justify-content: left !important;

        .breadcrumb-item {
            font-family: var(--tertiary-font);
            font-weight: bold;
            @include font-size(16px);
            letter-spacing: 0.1px;

            &.active {
                color: var(--primary);
            }
        }
    }

    .ProductDescriptionTab {
        h3 {
            color: $dark;
        }
    }
}
