﻿@import '../../../../../Common/src/scss/components/react/ProductGallery.scss';

.ProductGallery {
    > img {
        background: $white;
        margin-bottom: 0.5rem;
    }

    .slick-list {
        .slick-slide {
            button {
                background: $white;
            }
        }
    }
}
