@import '../../../../../Common/src/scss/components/react/SearchFiltersMenu.scss';

.SearchFiltersMenu {
    background-color: $gray-ghost;

    &__Open {
        svg.Icon {
            stroke: $dark;
        }
    }

    .Stack {
        &__divider {
            border-top-color: $dark !important;

            &--direction-row {
                border-left-color: $dark !important;
            }
        }
    }
}
