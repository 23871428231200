﻿.social {
    a {
        border-radius: 50%;
        display: inline-block;
        width: 1.5em;
        height: 1.5em;
        text-align: center;

        i {
            margin-right: 0;
            display: inline-block;
            @include font-size(14px);
            line-height: 2em;
        }
    }

    &.social__footer,
    &.social__mobile-nav {
        a {
            color: var(--primary);
            border: 1px solid $gray-dark;
        }
    }

    &.social__header {
        a {
            color: var(--primary);
            background-color: $black;
        }
    }
}
